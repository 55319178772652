<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">BUSINESS PERMIT (RELEASED FUND)</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-select
              v-model="year_of"
              class="mx-2"
              dense
              outlined
              label="Year of"
              :items="year_items"
              item-value="year"
              item-text="year"
              :rules="rules.combobox_rule"
              @change="selected_category"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              v-model="category_id"
              class="mx-2"
              dense
              outlined
              label="Category"
              :items="category_items"
              item-value="id"
              item-text="category"
              :rules="rules.combobox_rule"
              @change="selected_category"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              v-model="branch_id"
              class="mx-2"
              dense
              outlined
              label="Branch"
              :items="branch_items"
              item-value="id"
              item-text="branch_code"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="date"
              label="Date"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="date"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="amount"
              label="Amount"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
              min="0"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="credit_to"
              label="Credit To"
              dense
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-col cols="12" v-show="alert">
          <v-alert
            color="warning"
            text
            class="mb-0"

          >
            <div class="d-flex align-start">
              <v-icon color="warning">
                {{ icons.mdiAlertOutline }}
              </v-icon>

              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{alert_message}}
                </p>
              </div>
            </div>
          </v-alert>
        </v-col>
        <v-col cols="12" md="12">
          <v-btn
            class="w-full"
            color="primary"
            @click="save_request"
            v-if="!saving_data"
          >
            Save Request
          </v-btn>
          <v-progress-circular
            :size=50
            :width="5"
            color="primary"
            indeterminate
            v-else
          ></v-progress-circular>
        </v-col>
      </v-form>
      <v-data-table dense
                    :headers="headers"
                    :items="data_items"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <h2>Released P {{released_amount}}</h2>
            <v-divider class="mx-4" inset vertical></v-divider>
            <h2>COH P {{coh_amount}}</h2>
            <v-divider class="mx-4" inset vertical></v-divider>
            <h2>RF P {{rf_amount}}</h2>
            <v-divider class="mx-4" inset vertical></v-divider>
            <h2>Liquidated P {{liquidated_amount}}</h2>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.category }}
            </td>
            <td>
              {{ item.branch_code }}
            </td>
            <td>
              {{ item.total }}
            </td>
            <td>
              {{ item.return }}
            </td>
            <td>
              {{ item.liquidated }}
            </td>
            <td>
              {{ item.variance }}
            </td>
            <td>
              {{ item.date_1 }}
            </td>
            <td>
              {{ item.fund_1 }}
            </td>
            <td>
              {{ item.credit_to_1 }}
            </td>
            <td class="text-center">
              <v-icon
                v-show="!item.cant_delete_1"
                v-if="!is_deletingg"
                class="mr-2"
                color="error"
                @click="delete_fund(item.id_1)"
              >
                {{icons.mdiDelete}}
              </v-icon>
              <v-progress-circular color="info" indeterminate
                                   v-if="is_deletingg"></v-progress-circular>
            </td>

            <td>
              {{ item.date_2 }}
            </td>
            <td>
              {{ item.fund_2 }}
            </td>
            <td>
              {{ item.credit_to_2 }}
            </td>
            <td class="text-center">
              <v-icon
                v-show="!item.cant_delete_2"
                v-if="!is_deletingg"
                class="mr-2"
                color="error"
                @click="delete_fund(item.id_2)"
              >
                {{icons.mdiDelete}}
              </v-icon>
              <v-progress-circular color="info" indeterminate
                                   v-if="is_deletingg"></v-progress-circular>
            </td>

            <td>
              {{ item.date_3 }}
            </td>
            <td>
              {{ item.fund_3 }}
            </td>
            <td>
              {{ item.credit_to_3 }}
            </td>
            <td class="text-center">
              <v-icon
                v-show="!item.cant_delete_3"
                v-if="!is_deletingg"
                class="mr-2"
                color="error"
                @click="delete_fund(item.id_3)"
              >
                {{icons.mdiDelete}}
              </v-icon>
              <v-progress-circular color="info" indeterminate
                                   v-if="is_deletingg"></v-progress-circular>
            </td>

            <td>
              {{ item.date_4 }}
            </td>
            <td>
              {{ item.fund_4 }}
            </td>
            <td>
              {{ item.credit_to_4 }}
            </td>
            <td class="text-center">
              <v-icon
                v-show="!item.cant_delete_4"
                v-if="!is_deletingg"
                class="mr-2"
                color="error"
                @click="delete_fund(item.id_4)"
              >
                {{icons.mdiDelete}}
              </v-icon>
              <v-progress-circular color="info" indeterminate
                                   v-if="is_deletingg"></v-progress-circular>
            </td>

            <td>
              {{ item.date_5 }}
            </td>
            <td>
              {{ item.fund_5 }}
            </td>
            <td>
              {{ item.credit_to_5 }}
            </td>
            <td class="text-center">
              <v-icon
                v-show="!item.cant_delete_5"
                v-if="!is_deletingg"
                class="mr-2"
                color="error"
                @click="delete_fund(item.id_5)"
              >
                {{icons.mdiDelete}}
              </v-icon>
              <v-progress-circular color="info" indeterminate
                                   v-if="is_deletingg"></v-progress-circular>
            </td>

            <td>
              {{ item.date_6 }}
            </td>
            <td>
              {{ item.fund_6 }}
            </td>
            <td>
              {{ item.credit_to_6 }}
            </td>
            <td class="text-center">
              <v-icon
                v-show="!item.cant_delete_6"
                v-if="!is_deletingg"
                class="mr-2"
                color="error"
                @click="delete_fund(item.id_6)"
              >
                {{icons.mdiDelete}}
              </v-icon>
              <v-progress-circular color="info" indeterminate
                                   v-if="is_deletingg"></v-progress-circular>
            </td>

            <td>
              {{ item.date_7 }}
            </td>
            <td>
              {{ item.fund_7 }}
            </td>
            <td>
              {{ item.credit_to_7 }}
            </td>
            <td class="text-center">
              <v-icon
                v-show="!item.cant_delete_7"
                v-if="!is_deletingg"
                class="mr-2"
                color="error"
                @click="delete_fund(item.id_7)"
              >
                {{icons.mdiDelete}}
              </v-icon>
              <v-progress-circular color="info" indeterminate
                                   v-if="is_deletingg"></v-progress-circular>
            </td>

            <td>
              {{ item.date_8 }}
            </td>
            <td>
              {{ item.fund_8 }}
            </td>
            <td>
              {{ item.credit_to_8 }}
            </td>
            <td class="text-center">
              <v-icon
                v-show="!item.cant_delete_8"
                v-if="!is_deletingg"
                class="mr-2"
                color="error"
                @click="delete_fund(item.id_8)"
              >
                {{icons.mdiDelete}}
              </v-icon>
              <v-progress-circular color="info" indeterminate
                                   v-if="is_deletingg"></v-progress-circular>
            </td>

            <td>
              {{ item.date_9 }}
            </td>
            <td>
              {{ item.fund_9 }}
            </td>
            <td>
              {{ item.credit_to_9 }}
            </td>
            <td class="text-center">
              <v-icon
                v-show="!item.cant_delete_9"
                v-if="!is_deletingg"
                class="mr-2"
                color="error"
                @click="delete_fund(item.id_9)"
              >
                {{icons.mdiDelete}}
              </v-icon>
              <v-progress-circular color="info" indeterminate
                                   v-if="is_deletingg"></v-progress-circular>
            </td>

            <td>
              {{ item.date_10 }}
            </td>
            <td>
              {{ item.fund_10 }}
            </td>
            <td>
              {{ item.credit_to_10 }}
            </td>
            <td class="text-center">
              <v-icon
                v-show="!item.cant_delete_10"
                v-if="!is_deletingg"
                class="mr-2"
                color="error"
                @click="delete_fund(item.id_10)"
              >
                {{icons.mdiDelete}}
              </v-icon>
              <v-progress-circular color="info" indeterminate
                                   v-if="is_deletingg"></v-progress-circular>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiDelete, mdiClose} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      headers: [
        {text: 'Category', value: 'date_of_deposit', sortable: false},
        {text: 'Branch', value: 'tin', sortable: false},
        {text: 'Total', value: 'tin', sortable: false},
        {text: 'Return', value: 'tin', sortable: false},
        {text: 'Liquidation', value: 'tin', sortable: false},
        {text: 'Variance', value: 'tin', sortable: false},

        {text: 'Date', value: 'tin', sortable: false},
        {text: '1st Fund', value: 'tin', sortable: false},
        {text: 'Credit To', value: 'tin', sortable: false},
        {text: 'Delete', value: 'tin', sortable: false},

        {text: 'Date', value: 'tin', sortable: false},
        {text: '2nd Fund', value: 'tin', sortable: false},
        {text: 'Credit To', value: 'tin', sortable: false},
        {text: 'Delete', value: 'tin', sortable: false},

        {text: 'Date', value: 'tin', sortable: false},
        {text: '3rd Fund', value: 'tin', sortable: false},
        {text: 'Credit To', value: 'tin', sortable: false},
        {text: 'Delete', value: 'tin', sortable: false},

        {text: 'Date', value: 'tin', sortable: false},
        {text: '4th Fund', value: 'tin', sortable: false},
        {text: 'Credit To', value: 'tin', sortable: false},
        {text: 'Delete', value: 'tin', sortable: false},

        {text: 'Date', value: 'tin', sortable: false},
        {text: '5th Fund', value: 'tin', sortable: false},
        {text: 'Credit To', value: 'tin', sortable: false},
        {text: 'Delete', value: 'tin', sortable: false},

        {text: 'Date', value: 'tin', sortable: false},
        {text: '6th Fund', value: 'tin', sortable: false},
        {text: 'Credit To', value: 'tin', sortable: false},
        {text: 'Delete', value: 'tin', sortable: false},

        {text: 'Date', value: 'tin', sortable: false},
        {text: '7th Fund', value: 'tin', sortable: false},
        {text: 'Credit To', value: 'tin', sortable: false},
        {text: 'Delete', value: 'tin', sortable: false},

        {text: 'Date', value: 'tin', sortable: false},
        {text: '8th Fund', value: 'tin', sortable: false},
        {text: 'Credit To', value: 'tin', sortable: false},
        {text: 'Delete', value: 'tin', sortable: false},

        {text: 'Date', value: 'tin', sortable: false},
        {text: '9th Fund', value: 'tin', sortable: false},
        {text: 'Credit To', value: 'tin', sortable: false},
        {text: 'Delete', value: 'tin', sortable: false},

        {text: 'Date', value: 'tin', sortable: false},
        {text: '10th Fund', value: 'tin', sortable: false},
        {text: 'Credit To', value: 'tin', sortable: false},
        {text: 'Delete', value: 'tin', sortable: false},
      ],
      data_items: [],
      branch_items: [],
      category_items: [],
      year_items: [],
      saving_data: false,
      is_deletingg: false,
      alert: false,
      alert_message: '',
      credit_to: '',
      amount: '',
      date: '',
      branch_id: '',
      category_id: '',
      year_of: '',

      liquidated_amount: '0.00',
      rf_amount: '0.00',
      coh_amount: '0.00',
      released_amount: '0.00',
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiDelete,
          mdiClose,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('business_permit_released_fund', ['initialize_business_permit_released', 'list_of_business_permit_released', 'register_business_permit_released','delete_business_premit_fund']),
      initialize_data() {
        this.initialize_business_permit_released()
          .then(response => {
            this.year_items = response.data[0].year
            this.category_items = response.data[0].category
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_category() {
        this.is_deletingg=false
        var index = this.category_items.map(function (x) {
          return x.id;
        }).indexOf(this.category_id)
        if (index != -1) {
          this.branch_items = this.category_items[index].branch
        }
        this.list_of_business_permit_released({
          category_id: this.category_id,
          year: this.year_of,
        })
          .then(response => {
            this.liquidated_amount = response.data[0].liqui
            this.rf_amount = response.data[0].rf
            this.coh_amount = response.data[0].coh
            this.released_amount = response.data[0].released
            this.data_items = response.data[0].data
          })
          .catch(error => {
            console.log(error)
          })
      },
      save_request() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('year', this.year_of);
          data.append('branch_id', this.branch_id);
          data.append('credit_to', this.credit_to.toUpperCase());
          data.append('date', this.date);
          data.append('amount', this.amount);
          this.register_business_permit_released(data)
            .then(response => {
              this.saving_data = false
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              Object.assign(this.$data, initialState())
              this.initialize_data()
            })
            .catch(error => {
              console.log(error)
            });
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      delete_fund(value) {
        this.is_deletingg = true
        const data = new FormData()
        data.append('id', value);
        this.delete_business_premit_fund(data)
          .then(response => {
            this.saving_data = false
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.selected_category()
          })
          .catch(error => {
            console.log(error)
          });
      },
      selected_bank(value) {
        var index = this.bank_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          this.account_no = this.bank_items[index].account_no
        }
      },
    },
  }
</script>
